import fileDialog from 'file-dialog';

export default async () => {
  const files = await fileDialog();
  if (files[0]) {
    const [file] = files;

    return file;
  }
};

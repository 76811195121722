class FileUtils {
  static download(dataUrl, fileName) {
    var link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  }

  static async blobToDataUrl(blob) {
    return new Promise((resolve, reject) => {
      const a = new FileReader();
      a.onload = (e) => {
        resolve(e.target.result);
      };
      a.onerror = reject;
      a.readAsDataURL(blob);
    });
  }

  static async blobToBase64(blob) {
    return (await this.blobToDataUrl(blob)).replace(/^data:(.*,)?/, '');
  }

  static async downloadBlob(blob, fileName) {
    const url = await this.blobToDataUrl(blob);
    this.download(url, fileName ?? blob.name);
  }
}

export default FileUtils;

import ApiClient from 'modules/core/utils/ApiClient';

export const SET = 'SET_TRAININGS';
export const SET_EDITING = 'SET_EDITING';
export const SET_CURRENT_LESSON_NUMBER = 'SET_CURRENT_LESSON_NUMBER';
export const SET_LOADING = 'SET_TRAININGS_LOADING';
export const EDIT = 'EDIT_TRAINING';
export const CREATE = 'CREATE_TRAINING';
export const DELETE = 'DELETE_TRAINING';
export const EDIT_LESSON = 'EDIT_LESSON';
export const INSERT_LESSON = 'INSERT_LESSON';
export const CANCEL = 'CANCEL_TRAINING_EDITING';
export const CLEAR_TRAININGS = 'CLEAR_TRAININGS';
export const SET_ERROR = 'SET_TRAINING_ERROR';
export const REFRESH_TRAININGS = 'REFRESH_TRAININGS';
export const UPDATE_PROGRESSING = 'UPDATE_PROGRESSING';
export const UPDATE_TRAININGS = 'UPDATE_TRAININGS';

const setTrainings = (data, trainingsTotalItems, clear) => ({
  type: SET,
  data,
  trainingsTotalItems,
  clear,
});

export const setEdiditing = (training) => ({ type: SET_EDITING, training });
export const setCurrentLessonNumber = (number) => ({
  type: SET_CURRENT_LESSON_NUMBER,
  number,
});

export const editLesson = (lesson, orderNumber) => ({
  type: EDIT_LESSON,
  lesson,
  orderNumber,
});

export const insertLesson = (orderNumber) => ({
  type: INSERT_LESSON,
  // lesson,
  orderNumber,
});

export const editTraining = (id, training) => ({
  type: EDIT,
  id,
  training,
});

export const cancelEditing = () => ({
  type: CANCEL,
});

export const setError = (error) => ({ type: SET_ERROR, error });

export const refreshTrainings = () => ({ type: REFRESH_TRAININGS });

export const updateProgressing = (progressing) => ({
  type: UPDATE_PROGRESSING,
  progressing,
});

export const fetchTrainings = (pageSize) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.trainings.loading ||
      state.trainings.trainings.length >= state.trainings.trainingsTotalItems
    ) {
      return;
    }
    dispatch({ type: SET_LOADING, value: true });
    const shouldClear = state.trainings.trainings.length < pageSize;
    const page = shouldClear
      ? 0
      : (state.trainings.trainings.length / pageSize) ^ 0;
    const response = await ApiClient.fetchTrainings(page + 1, pageSize);
    dispatch(setTrainings(response.entities, response.totalItems, shouldClear));
    dispatch({ type: SET_LOADING, value: false });
  };
};

export const fetchTrainingsWithItems = (pageSize) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.trainings.loading ||
      state.trainings.trainings.length >= state.trainings.trainingsTotalItems
    ) {
      return;
    }
    dispatch({ type: SET_LOADING, value: true });
    const shouldClear = state.trainings.trainings.length < pageSize;
    const page = shouldClear
      ? 0
      : (state.trainings.trainings.length / pageSize) ^ 0;
    const response = await ApiClient.fetchTrainingsWithItems(
      page + 1,
      pageSize
    );
    dispatch(setTrainings(response.entities, response.totalItems, shouldClear));
    dispatch({ type: SET_LOADING, value: false });
  };
};

export const fetchEditingTraining = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: true });
    const response = await ApiClient.fetchFullTraining(id);
    const fetchedTraining = { ...response, id, price: response.price / 100 };
    dispatch(setEdiditing(fetchedTraining));
    dispatch({ type: SET_LOADING, value: false });
    return fetchedTraining;
  };
};

export const startEditTraining = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: true });
    try {
      const editing = getState().trainings.editing;
      await ApiClient.updateTraining(editing);
      dispatch({ type: CLEAR_TRAININGS });
      dispatch({ type: SET_LOADING, value: false });
      setTimeout(() => dispatch(cancelEditing()), 350);
    } catch (err) {
      const errors = Object.entries(err.response.data.errors).map((i) =>
        i[1].toString()
      );
      console.log(errors, 'training creating error');
      dispatch({ type: SET_LOADING, value: false });
      dispatch(setError(errors));

      throw err;
    }
  };
};

export const startDeleteTraining = (id) => {
  return async (dispatch) => {
    await ApiClient.deleteTraining(id);
    dispatch({ type: DELETE, id });
  };
};

export const startCreateTraining = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: true });
    try {
      const editing = getState().trainings.editing;
      await ApiClient.createTraining(editing);
      dispatch({ type: CLEAR_TRAININGS });
      dispatch({ type: SET_LOADING, value: false });
      setTimeout(() => dispatch(cancelEditing()), 350);
    } catch (err) {
      const errors = Object.entries(err.response.data.errors).map((i) =>
        i[1].toString()
      );
      console.log(errors, 'training creating error');
      dispatch({ type: SET_LOADING, value: false });
      dispatch(setError(errors));

      throw err;
    }
  };
};

export const startGetTrainingProgress = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: true });
    try {
      const progressing = getState().trainings.progressing;
      // if (progressing?.id === id) {
      //   dispatch({ type: SET_LOADING, value: false });
      //   return;
      // }
      // debugger;
      const shortTraining = getState().trainings.trainings.find(
        (t) => t.id === id
      );
      if (shortTraining) {
        dispatch(
          updateProgressing({
            id,
            heading: shortTraining.heading,
            totalTimeInMin: shortTraining.timeInMin,
            lessons: [],
          })
        );
      }
      await dispatch(
        updateProgressing({ id, ...(await ApiClient.getUserTraining(id)) })
      );
      dispatch({ type: SET_LOADING, value: false });
    } catch (err) {
      console.log(err, err.response);
      dispatch({ type: SET_LOADING, value: false });
      throw err;
    }
  };
};

export const startGetLessonProgress = (lessonId, trainingId) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, value: true });
    // debugger;
    try {
      const state = getState().trainings;
      const progressing = state.progressing;
      if (progressing?.id === trainingId) {
        const lesson = progressing.lessons.find((l) => l.id === lessonId);
        if (
          lesson &&
          lesson.lessonTopics &&
          (lesson?.lessonTopics?.length !== 0 || lesson.test)
        ) {
          dispatch({ type: SET_LOADING, value: false });
          return;
        }
      }
      const lesson = await ApiClient.getLesson(lessonId);
      dispatch(
        updateProgressing({
          lessons: progressing?.lessons?.map((l) =>
            l.id === lessonId ? { ...l, ...lesson } : l
          ) ?? [{ ...lesson, id: lessonId }],
        })
      );
      dispatch({ type: SET_LOADING, value: false });
    } catch (err) {
      console.log(err, err.response);
      dispatch({ type: SET_LOADING, value: false });
      throw err;
    }
  };
};

export const startGetTopicProgress = (topicId, lessonId) => {
  return async (dispatch, getState) => {
    // dispatch({ type: SET_LOADING, value: true });
    try {
      const state = getState().trainings;
      const progressing = state.progressing;

      const topic = await ApiClient.getTopic(topicId);

      dispatch(
        updateProgressing({
          lessons: progressing?.lessons?.map((l) =>
            l.id === lessonId
              ? {
                  ...l,
                  lessonTopics: l.lessonTopics.map((t) =>
                    t.id === topicId ? { ...t, ...topic } : t
                  ),
                }
              : l
          ),
        })
      );
    } catch (err) {
      console.log(err, err.response);
      // dispatch({ type: SET_LOADING, value: false });
      throw err;
    }
  };
};

export const startCompleteTopic = (topicId, lessonId) => {
  return async (dispatch, getState) => {
    const progressing = getState().trainings.progressing;
    let completeResponse;

    const updates = {
      lessons: await Promise.all(
        progressing?.lessons?.map(async (l) => {
          if (l.id === lessonId) {
            const updatedLesson = {
              ...l,
              lessonTopics: await Promise.all(
                l.lessonTopics.map(async (t) => {
                  if (t.id === topicId && !t.completed) {
                    completeResponse = await ApiClient.completeTopic(topicId);

                    return { ...t, completed: true };
                  }
                  return t;
                })
              ),
            };

            console.log('lesson: ', completeResponse);
            if (completeResponse?.isLessonFinished) {
              updatedLesson.completed = true;
            }
            return updatedLesson;
          }
          return l;
        })
      ),
    };
    if (completeResponse) {
      if (
        completeResponse.isLessonFinished &&
        progressing.completedLessonCount !== undefined
      ) {
        updates.completedLessonCount = progressing.completedLessonCount + 1;
      }

      if (progressing.completedTopicCount !== undefined) {
        updates.completedTopicCount = progressing.completedTopicCount + 1;
      }

      if (completeResponse.isTrainingFinished) {
        updates.completed = true;
        updates.comletedTimeInMin = progressing.totalTimeInMin;
        updates.completedTopicCount = progressing.totalTopicCount;
      }

      dispatch(updateProgressing(updates));
    }

    if (completeResponse?.isLessonFinished) {
      const trainings = getState().trainings.trainings;
      if (trainings.length > 0) {
        dispatch({
          type: UPDATE_TRAININGS,
          trainings: trainings.map((t) => {
            if (t.id === progressing.id) {
              return {
                ...t,
                completedLessons: t.completedLessons + 1,
              };
            }
            return t;
          }),
        });
      }
    }
  };
};

export const startVerifyTest = (testData) => {
  return async (dispatch, getState) => {
    const progressing = getState().trainings.progressing;
    let result;
    const updates = {
      ...progressing,
      lessons: await Promise.all(
        progressing.lessons.map(async (l) => {
          if (l?.test?.id === testData.id) {
            result = await ApiClient.verifyTest(testData);
            return {
              ...l,
              completed: result.passed,
              test: {
                ...l.test,
                completed: result.passed,
                lastMark: result.accuracy,
                testAccessible: result.passed,
              },
            };
          }
          return l;
        })
      ),
      completed: result.isTrainingFinished,
    };

    if (result?.isTrainingFinished && progressing.totalTimeInMin) {
      updates.comletedTimeInMin = progressing.totalTimeInMin;
      updates.completedLessonCount = progressing.completedLessonCount + 1;
    }

    dispatch(updateProgressing(updates));
    const trainings = getState().trainings.trainings;
    if (trainings.length > 0 && result?.isTrainingFinished) {
      dispatch({
        type: UPDATE_TRAININGS,
        trainings: trainings.map((t) => {
          if (t.id === progressing.id) {
            return {
              ...t,
              completedLessons: t.completedLessons + 1,
            };
          }
          return t;
        }),
      });
    }

    return result;
  };
};

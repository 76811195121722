import React from 'react';

import ResponsiveCarousel from 'modules/core/components/ResponsiveCarousel';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';

import img1 from 'assets/images/carouselCareer/img1.jpg';
import img2 from 'assets/images/carouselCareer/img2.jpg';
import img3 from 'assets/images/carouselCareer/img3.jpg';

import './styles.scss';

const CareerCarousel = () => {
  return (
    <BreakpointContextConsumer>
      {({ isMobile }) => (
        <div className="CareerCarousel__root">
          {isMobile && (
            <ResponsiveCarousel
              className="test"
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={false}
              showThumbs={false}
              autoPlay={false}
              swipeable={isMobile ? true : false}
              emulateTouch={isMobile ? true : false}
              useKeyboardArrows={false}
              centerMode
              centerSlidePercentage={70}
              width={'100%'}
            >
              <div className="CareerCarousel__slider">
                <img src={img1} alt="" />
              </div>
              <div className="CareerCarousel__slider">
                <img src={img2} alt="" />
              </div>
              <div className="CareerCarousel__slider">
                <img src={img3} alt="" />
              </div>
            </ResponsiveCarousel>
          )}
          {!isMobile && (
            <div className="CareerCarousel__destroy">
              <div className="CareerCarousel__col">
                <img src={img1} alt="" />
              </div>
              <div className="CareerCarousel__col">
                <img src={img2} alt="" />
              </div>
              <div className="CareerCarousel__col">
                <img src={img3} alt="" />
              </div>
            </div>
          )}
        </div>
      )}
    </BreakpointContextConsumer>
  );
};

export default CareerCarousel;

import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
import { useParams } from 'react-router-dom';

import ApiClient from 'modules/core/utils/ApiClient';
import selectFile from 'modules/career/utils/selectFile';
import { prettifyError } from 'modules/core/utils/error';
import Container from 'modules/core/components/Container';
import Input from 'modules/core/components/Input';
import CareerPopup from 'modules/career/components/CareerPopup';
import './styles.scss';

const CareerApplyPage = () => {
  const { id, name: positionName } = useParams();

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [file, setFile] = React.useState();
  const [submited, setSubmited] = React.useState(false);
  const [popupShown, setPopupShown] = React.useState(false);
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState();

  const handleSubmit = React.useCallback(async () => {
    if (!file || !email || !name || !isEmail(email)) {
      setSubmited(true);
      return;
    }

    setLoading(true);
    try {
      await ApiClient.createJobApplication({
        jobPositionId: id,
        email,
        name,
        resume: file,
      });
      setPopupShown(true);
    } catch (err) {
      console.log(err, err.response);
      setError(prettifyError(err));
    }
    setLoading(false);
    setSubmited(true);
  }, [id, email, name, file]);

  const handleCareerPopupToggle = React.useCallback(() => {
    setPopupShown(!popupShown);
  }, [popupShown]);

  const handleSelectedFile = React.useCallback(async () => {
    try {
      const selectedFile = await selectFile();
      setFile(selectedFile);
    } catch (err) {
      console.log(err);
    }
  }, []);
  const handleDeleteFile = React.useCallback(() => {
    setFile();
  }, []);

  const emailInputHandler = React.useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );
  const nameInputHandler = React.useCallback(
    (e) => {
      setName(e.target.value);
    },
    [setName]
  );

  return (
    <Container className="CareerApplyPage__root">
      <CareerPopup show={popupShown} onClose={handleCareerPopupToggle} />
      <div className="CareerApplyPage__container">
        <h2>Fill out the form for the position: {positionName}</h2>
        <p>We will contact you back.</p>
        <div className="CareerApplyPage__form">
          <Input
            type="text"
            id="fyc_admin_name"
            placeholder=" "
            label="Name Surname"
            submited={submited}
            // maxLength={3}
            value={name}
            onChange={nameInputHandler}
            isInvalid={submited && !name}
            errorFeedback=""
            rootClass="LoginPage__input"
          />
          <Input
            type="email"
            id="fyc_admin_mail"
            placeholder=" "
            label="Email"
            submited={submited}
            value={email}
            onChange={emailInputHandler}
            isInvalid={submited && !isEmail(email)}
            errorFeedback="Email is not valid."
            rootClass="LoginPage__input"
          />
          <div className="CareerApplyPage__upload">
            <Button onClick={handleSelectedFile} variant="outline-dark">
              Upload resume
            </Button>
            <div
              className={`CareerApplyPage__upload-container${
                submited && !file ? '-error' : ''
              }`}
            >
              {file?.name}
              <Button
                variant="link"
                className="CareerApplyPage__delete"
                onClick={handleDeleteFile}
              />
            </div>
          </div>
          {error && <h2 className="serverError">{error}</h2>}
          <Button
            className="CareerApplyPage__submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            Send
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                variant="secondary"
                className="buttonSpinner"
              />
            )}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CareerApplyPage;

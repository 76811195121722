import React from 'react';
import { Link } from 'react-router-dom';

import useParams from 'modules/core/hooks/wrappedUseParams';
import ApiClient from 'modules/core/utils/ApiClient';
import Container from 'modules/core/components/Container';
import WithLoading from 'modules/core/components/WithLoading';

import './styles.scss';

const CareerViewPositionPage = () => {
  const { id } = useParams();
  const [position, setPosition] = React.useState();

  React.useEffect(() => {
    if (id && !position)
      (async () => {
        try {
          setPosition(await ApiClient.getJobPosition(id));
        } catch (err) {
          console.log(err, err.data);
        }
      })();
  }, [id, position]);

  return (
    <Container className="CareerViewPositionPage__root">
      <div className="CareerViewPositionPage__container">
        <WithLoading loading={!position}>
          <div className="CareerViewPositionPage__top">
            <div className="CareerViewPositionPage__left">
              <h2>{position?.name}</h2>
              <span>{position?.shortDescription}</span>
              <p>
                {position?.experience} <span> years of experience</span>
              </p>
            </div>
            <div className="CareerViewPositionPage__right">
              <Link
                to={`/career/apply/${id}/${position?.name}`}
                className="btn btn-primary"
              >
                Apply
              </Link>
            </div>
          </div>
          <div className="CareerViewPositionPage__description">
            <div className="CareerViewPositionPage__row">
              <h3>Position</h3>
              <p>{position?.description}</p>
            </div>
            <div className="CareerViewPositionPage__row">
              <h3>Required skills</h3>
              <p>{position?.requiredSkills}</p>
            </div>
            <Link
              to={`/career/apply/${id}/${position?.name}`}
              className="CareerViewPositionPage__btn btn btn-primary"
            >
              Apply
            </Link>
          </div>
        </WithLoading>
      </div>
    </Container>
  );
};

export default CareerViewPositionPage;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  startFetchAddress,
  updateAddress,
} from 'modules/core/store/actions/cardsActions';
import WithLoading from 'modules/core/components/WithLoading';
import { prettifyError } from 'modules/core/utils/error';
import useAuth from 'modules/auth/hooks/useAuth';

const WithPaymentAddress = (WrappedComponent) => {
  const NewComponent = (props) => {
    const {
      shippingAddress: storedShipmentAddress,
      billingAddress: storedBillingAddress,
      addressesEqual,
      addressLoaded: _loaded,
      addressLoading: _loading,
    } = useSelector((state) => state.cards);
    const { isAuthenticated } = useAuth();

    const { loaded, loading } = React.useMemo(() => {
      return isAuthenticated
        ? { loaded: _loaded, loading: _loading }
        : { loaded: true, loading: false };
    }, [_loaded, _loading, isAuthenticated]);

    const [shippingAddress, setShippingAddress] = React.useState(
      storedShipmentAddress
    );
    const [billingAddress, setBillingAddress] = React.useState(
      storedBillingAddress
    );
    const [billingAddressCheked, setBillingAddressCheked] = React.useState(
      addressesEqual
    );
    const [saveChanges, setSaveChanges] = React.useState(false);
    const [error, setError] = React.useState('');

    const dispatch = useDispatch();

    React.useEffect(() => {
      if (!loaded && !loading && isAuthenticated) {
        dispatch(startFetchAddress());
      }
    }, [dispatch, loaded, loading, isAuthenticated]);

    React.useEffect(() => {
      setShippingAddress(storedShipmentAddress);
      setBillingAddress(storedBillingAddress);
      setBillingAddressCheked(addressesEqual);
    }, [storedShipmentAddress, storedBillingAddress, addressesEqual]);

    const handleChangeShippingAddress = React.useCallback(
      (e) => {
        const { name, value } = e.target;
        setShippingAddress({ ...shippingAddress, [name]: value });
      },
      [shippingAddress]
    );
    const handleChangeBillingAddress = React.useCallback(
      (e) => {
        const { name, value } = e.target;
        setBillingAddress({ ...billingAddress, [name]: value });
      },
      [billingAddress]
    );

    const toggleSaveChanges = React.useCallback(() => {
      setSaveChanges(!saveChanges);
    }, [saveChanges]);

    const handleSubmit = React.useCallback(async () => {
      try {
        console.log(shippingAddress, billingAddress);
        await dispatch(
          updateAddress(
            shippingAddress,
            billingAddress,
            billingAddressCheked,
            saveChanges
          )
        );
      } catch (err) {
        console.log(err);
        setError(prettifyError(err));
      }
    }, [
      shippingAddress,
      billingAddress,
      dispatch,
      saveChanges,
      billingAddressCheked,
    ]);

    const toggleBillingAddressCheked = React.useCallback(() => {
      setBillingAddressCheked(!billingAddressCheked);
    }, [billingAddressCheked]);

    return (
      <WithLoading loading={!loaded}>
        {error && <h2 className="serverError">{error}</h2>}
        <WrappedComponent
          shippingAddress={shippingAddress}
          handleChangeShippingAddress={handleChangeShippingAddress}
          billingAddress={billingAddress}
          handleChangeBillingAddress={handleChangeBillingAddress}
          onSubmit={handleSubmit}
          billingAddressCheked={billingAddressCheked}
          toggleBillingAddressCheked={toggleBillingAddressCheked}
          loading={loading}
          isAuthenticated={isAuthenticated}
          toggleSaveChanges={toggleSaveChanges}
          {...props}
        />
      </WithLoading>
    );
  };
  return NewComponent;
};

export default WithPaymentAddress;

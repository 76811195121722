import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import localStorageService from 'modules/core/utils/localStorageService';
import rootReducer from './reducers/rootReducer';

const tokenData = localStorageService.getTokenData();
const userInfo = localStorageService.getUserInfo();
const cart = localStorageService.getCart();

const initialState = {
  auth: {
    tokenData,
    isAuthenticated: !!tokenData,
  },
};

if (userInfo) {
  initialState.user = { ...userInfo, loading: false, loaded: false };
}

if (cart) {
  initialState.cart = JSON.parse(cart);
}

export default !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ? createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(thunk))
    )
  : createStore(rootReducer, initialState, applyMiddleware(thunk));

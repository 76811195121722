import ApiClient from 'modules/core/utils/ApiClient';

export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const TOGGLE_LOADING = 'CARDS_TOGGLE_LOADING';
export const MAKE_DEFAULT = 'MAKE_DEFAULT';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const TOGGLE_ADDRESS_LOADING = 'TOGGLE_ADDRESS_LOADING';
export const SELECT_METHOD = 'SELECT_PAYMENT_METHOD';
export const RESET = 'PAYMENT_METHODS_RESET';

export const setSelectedPaymentMethod = (selectedMethod) => ({
  type: SELECT_METHOD,
  selectedMethod,
});

export const resetPaymentMethods = () => ({
  type: RESET,
});
export const startFetchPaymentMethods = () => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING });
    const paymentMethods = await ApiClient.getPaymentMethods();
    dispatch({ type: SET_PAYMENT_METHODS, data: paymentMethods });
  };
};

export const startAddPaymentMethod = ({ number, expMonth, expYear, cvc }) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING });
    try {
      const response = await ApiClient.addPaymentMethod({
        number,
        expMonth,
        expYear,
        cvc,
      });
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_METHODS,
          updates: [response.data.value],
        });
      }
      dispatch({ type: TOGGLE_LOADING });
    } catch (err) {
      dispatch({ type: TOGGLE_LOADING });

      throw err;
    }
  };
};

export const startDeletePaymentMethod = (paymentId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING });
    try {
      const response = await ApiClient.deletePaymentMethod(paymentId);
      dispatch({ type: REMOVE_PAYMENT_METHOD, id: paymentId });
      dispatch({ type: TOGGLE_LOADING });

      return response.data;
    } catch (err) {
      dispatch({ type: TOGGLE_LOADING });

      throw err;
    }
  };
};

export const startMakeDefault = (paymentId) => {
  return async (dispatch) => {
    dispatch({ type: TOGGLE_LOADING });
    try {
      const response = await ApiClient.setDefaultPaymentMethod(paymentId);
      dispatch({ type: MAKE_DEFAULT, id: paymentId });
      dispatch({ type: TOGGLE_LOADING });

      return response.data;
    } catch (err) {
      dispatch({ type: TOGGLE_LOADING });

      throw err;
    }
  };
};

// Address

export const startFetchAddress = () => {
  return async (dispatch) => {
    try {
      const {
        shippingAddress,
        billingAddress,
        addressesEqual,
      } = await ApiClient.getAddress();
      dispatch({
        type: UPDATE_ADDRESS,
        shippingAddress,
        billingAddress,
        addressesEqual,
      });
    } catch (err) {
      console.log(err, err.response);
    }
  };
};

export const updateAddress = (
  shippingAddress,
  billingAddress,
  addressesEqual,
  save = true
) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_ADDRESS_LOADING });
    if (getState().auth.isAuthenticated && save) {
      await ApiClient.updateAddress(
        shippingAddress,
        billingAddress,
        addressesEqual
      );
    }
    dispatch({
      type: UPDATE_ADDRESS,
      shippingAddress,
      billingAddress,
      addressesEqual,
    });
    dispatch({ type: TOGGLE_ADDRESS_LOADING });
  };
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import './styles.scss';

const CareerPopup = ({ show, onClose }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      size={'xl'}
      centered
      className="CareerPopup-modal"
    >
      <div className="CareerPopup__root">
        <div className="CareerPopup__container">
          <svg
            width="71"
            height="71"
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.5005 69.4565C54.2541 69.4565 69.457 54.2536 69.457 35.5C69.457 16.7463 54.2541 1.54346 35.5005 1.54346C16.7468 1.54346 1.54395 16.7463 1.54395 35.5C1.54395 54.2536 16.7468 69.4565 35.5005 69.4565Z"
              stroke="#F2C94C"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M50.6272 50.6261C46.6141 54.6391 41.3663 57.1087 35.5011 57.1087C29.6359 57.1087 24.0793 54.6391 20.375 50.6261"
              stroke="#F2C94C"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.6094 30.8695C23.3143 30.8695 24.6964 29.4874 24.6964 27.7825C24.6964 26.0776 23.3143 24.6956 21.6094 24.6956C19.9045 24.6956 18.5225 26.0776 18.5225 27.7825C18.5225 29.4874 19.9045 30.8695 21.6094 30.8695Z"
              fill="#F2C94C"
            />
            <path
              d="M49.3916 30.8695C51.0965 30.8695 52.4786 29.4874 52.4786 27.7825C52.4786 26.0776 51.0965 24.6956 49.3916 24.6956C47.6868 24.6956 46.3047 26.0776 46.3047 27.7825C46.3047 29.4874 47.6868 30.8695 49.3916 30.8695Z"
              fill="#F2C94C"
            />
          </svg>
          <h3>Thank you for submitting an application!</h3>
          <p>We will get to you shortly</p>
          <NavLink to="/career" className="btn btn-primary">
            Back to positions
          </NavLink>
        </div>
      </div>
    </Modal>
  );
};

export default CareerPopup;

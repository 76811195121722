import React from 'react';
import { FormControl } from 'react-bootstrap';

import './styles.scss';

const LabeledFormControl = React.forwardRef(
  ({ label, id, className, ...rest }, ref) => (
    <>
      {label && (
        <label htmlFor={id} className="FormControl__label">
          {label}
        </label>
      )}
      <FormControl
        ref={ref}
        id={id}
        aria-describedby="basic-addon3"
        className={'FormControl__input ' + (className ?? '')}
        autoComplete="off"
        {...rest}
      />
    </>
  )
);

export default LabeledFormControl;

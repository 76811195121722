import React from 'react';

import Container from 'modules/core/components/Container';
import TitleDecor from 'modules/core/components/TitleDecor';
import CareerCarousel from 'modules/career/components/CareerCarousel';
import CareerPosition from 'modules/career/components/CareerPosition';
import CareerBlockQuote from 'modules/career/components/CareerBlockQuote';

import './styles.scss';

const CareerPage = () => {
  return (
    <Container className="CareerPage__root">
      <div className="CareerPage__container">
        <div className="CareerPage__title">
          <TitleDecor content="Let’s work together" />
          <p className="CareerPage__subtitle">
            Work hard with highly motivated team and talented people.
          </p>
        </div>
        <CareerCarousel />
        <CareerPosition />
        <CareerBlockQuote />
      </div>
    </Container>
  );
};

export default CareerPage;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Container from 'modules/core/components/Container';
import useAuth from 'modules/auth/hooks/useAuth';
import { trainingsRoutes } from 'modules/trainings/routes/constants';
import { adminTrainingsRoutes } from 'modules/admin-trainings/routes/constants';
import SecurityGroupService from 'modules/core/utils/SecurityGroupService';

// pages
import TrainingsRootPage from 'modules/trainings-root/pages/TrainingsRootPage';

import './styles.scss';
import { useSelector } from 'react-redux';

const TrainingsRoutes = () => {
  const { isAuthenticated } = useAuth();
  const userMode = useSelector((state) => state.user.userMode);
  const groups = useSelector((state) => state.user.securityGroups);

  return (
    <div className="Trainings__root">
      <Container contentClassName="Trainings__content">
        {!isAuthenticated ? (
          <Switch>
            <Route
              path={trainingsRoutes.root}
              component={TrainingsRootPage}
              exact
            />
          </Switch>
        ) : userMode === 'admin' &&
          SecurityGroupService.containsPermission(groups, 'Trainings') ? (
          <Redirect to={adminTrainingsRoutes.trainings} />
        ) : (
          <Redirect to={trainingsRoutes.authenticated} />
        )}
      </Container>
    </div>
  );
};

export default TrainingsRoutes;

import { SET_RESIDENTS_LIST } from 'modules/core/store/actions/residentsActions';
import { LOGOUT } from 'modules/auth/store/actions/authActions';
import { UPDATE_CURRENT_FACILITY } from 'modules/core/store/actions/userInfoActions';

const initialState = {
  residents: [],
  totalItemsCount: 9999,
};

const residentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESIDENTS_LIST:
      return {
        ...state,
        residents: action.residents,
        totalItemsCount: action.totalItemsCount,
      };
    case UPDATE_CURRENT_FACILITY:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default residentsReducer;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { careerRoutes } from 'modules/career/routes/constants';

import CareerPage from 'modules/career/pages/CareerPage';
import CareerViewPositionPage from 'modules/career/pages/CareerViewPositionPage';
import CareerApplyPage from 'modules/career/pages/CareerApplyPage';

import ScrollToTop from 'modules/core/utils/ScrollToTop';
const CareerRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path={careerRoutes.root} component={CareerPage} exact />
        <Route path={careerRoutes.apply} component={CareerApplyPage} />

        <Route
          path={careerRoutes.position}
          component={CareerViewPositionPage}
        />
      </Switch>
    </>
  );
};

export default CareerRoutes;

import history from 'modules/core/utils/history';
import { adminResidentsRoutes } from 'modules/admin-residents/routes/constants';
import { adminFacilityRoutes } from 'modules/admin-facility/routes/constants';
import { donationsTabelRoutes } from 'modules/donations-table/routes/constants';
import { adminStaffRoutes } from 'modules/admin-staff/routes/constants';

const navigateTo = (item) => {
  switch (item.notificationType) {
    case 1: {
      return history.push(adminResidentsRoutes.root);
    }
    case 2: {
      return history.push(adminResidentsRoutes.root);
    }
    case 3: {
      return history.push(adminResidentsRoutes.root);
    }
    case 4: {
      return history.push(adminResidentsRoutes.root);
    }
    case 5: {
      history.push(
        adminResidentsRoutes.resident.replace(
          ':residentId',
          item.metadata.residentId
        )
      );
      window.location.reload();
      break;
    }
    case 6: {
      return history.push(
        adminFacilityRoutes.facilityInfoFn(
          item.facilityWhereNotificationTookPlaceId
        )
      );
    }
    case 7: {
      return history.push(
        adminFacilityRoutes.facilityInfoFn(
          item.facilityWhereNotificationTookPlaceId
        )
      );
    }
    case 8: {
      return history.push(
        adminResidentsRoutes.reportSectionFn(
          item.metadata.residentId,
          'incident',
          item.metadata.incidentReportId,
          'first-step'
        )
      );
    }
    case 9: {
      return history.push(
        adminResidentsRoutes.reportSectionFn(
          item.metadata.residentId,
          'disruption',
          item.metadata.disruptionReportId,
          'first-step'
        )
      );
    }
    case 10: {
      return history.push(donationsTabelRoutes.root);
    }
    case 11: {
      return history.push(adminStaffRoutes.root);
    }
    case 12: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventId: item.metadata.scheduleEventId },
      });
    }
    case 13: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventId: item.metadata.scheduleEventId },
      });
    }
    case 14: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventDate: item.metadata.scheduleEventMonth },
      });
    }
    case 15: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventId: item.metadata.scheduleEventId },
      });
    }
    case 16: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventId: item.metadata.scheduleEventId },
      });
    }
    case 17: {
      return history.push({
        pathname: adminResidentsRoutes.schedule.replace(
          ':residentId',
          item.metadata.residentId
        ),
        state: { eventId: item.metadata.scheduleEventId },
      });
    }
    case 18: {
      return history.push(
        adminResidentsRoutes.resident.replace(
          ':residentId',
          item.metadata.residentId
        )
      );
    }
    case 19: {
      return history.push(
        adminFacilityRoutes.facilityInfoFn(
          item.facilityWhereNotificationTookPlaceId
        )
      );
    }
    default:
      return;
  }
};
export default navigateTo;

import React from 'react';
import PropTypes from 'prop-types';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';

import './styles.scss';

const Container = ({
  paddingHorizontalMobile = 0,
  // paddingHorizontalDesktop = 0,
  paddingVerticalMobile = 0,
  paddingVerticalDesktop = 0,
  desktopMaxContentWidth = 1180,
  desktopPaddingsScalar = 0.15,
  className,
  children,
  contentClassName = '',
}) => {
  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint, width }) => {
        /*let calcMedium = (lgValue) => parseInt(lgValue) / 2 + 'px';
        let containerStyle;
        switch (breakpoint) {
          case 'xs':
          case 'sm':
            containerStyle = {
              paddingLeft: paddingHorizontalMobile,
              paddingRight: paddingHorizontalMobile,
              paddingTop: paddingVerticalMobile,
              paddingBottom: paddingVerticalMobile,
            };
            break;
          case 'md':
            containerStyle = {
              paddingLeft: calcMedium(paddingHorizontalDesktop),
              paddingRight: calcMedium(paddingHorizontalDesktop),
              paddingTop: calcMedium(paddingVerticalDesktop),
              paddingBottom: calcMedium(paddingVerticalDesktop),
            };
            break;
          case 'lg':
            containerStyle = {
              paddingLeft: paddingHorizontalDesktop,
              paddingRight: paddingHorizontalDesktop,
              paddingTop: paddingVerticalDesktop,
              paddingBottom: paddingVerticalDesktop,
            };
            break;
          default:
            containerStyle = {};
        }*/
        let containerStyle = {};
        let contentStyle;
        switch (breakpoint) {
          case 'xs':
          case 'sm':
            contentStyle = {
              paddingLeft: paddingHorizontalMobile,
              paddingRight: paddingHorizontalMobile,
              paddingTop: paddingVerticalMobile,
              paddingBottom: paddingVerticalMobile,
            };
            break;
          case 'md':
          case 'lg':
          case 'xl':
            const padding = (width * desktopPaddingsScalar) / 2;
            contentStyle = {
              paddingLeft: padding,
              paddingRight: padding,
              paddingTop:
                paddingVerticalDesktop ^ 0 ? paddingVerticalDesktop : undefined,
              paddingBottom: paddingVerticalDesktop,
            };
            containerStyle = { maxWidth: desktopMaxContentWidth + 'px' };
            break;
          default:
            contentStyle = {};
        }

        return (
          <div
            className={'Container__root ' + (className ?? '')}
            style={contentStyle}
          >
            <div
              className={'Container__content ' + contentClassName}
              style={containerStyle}
            >
              {children}
            </div>
          </div>
        );
      }}
    </BreakpointContextConsumer>
  );
};

Container.propTypes = {
  paddingHorizontalDesktop: PropTypes.string,
  paddingHorizontalMobile: PropTypes.string,
  paddingVerticalMobile: PropTypes.string,
  paddingVerticalDesktop: PropTypes.string,
  // paddingHorizontalMedium: PropTypes.string,
  // paddingVerticalMedium: PropTypes.string,
  className: PropTypes.string,
};

export default Container;

import axios from 'axios';
import * as signalR from '@microsoft/signalr';

import localStorageService from './localStorageService';
import store from 'modules/core/store';
import { login, logout } from 'modules/auth/store/actions/authActions';
import { updateUserSecurityGroups } from 'modules/core/store/actions/userInfoActions';
import history from 'modules/core/utils/history';
import { loginRoutes } from 'modules/login/routes/constants';
import { coreRoutes } from 'modules/core/routes/constants';
import ImageCompressionService from 'modules/core/utils/ImageCompressionService';
import FileUtils from 'modules/core/utils/FileUtils';
import serializeToFormData from 'modules/admin/utils/serializeToFormData';

class ApiClient {
  static API_URL = process.env.REACT_APP_API_DEV_URL || process.env.API_URL;
  static isRefreshing = false;
  static refreshWaitingQueue = [];
  // key - image hash; value - Promise
  static imageRequestQueueMap = new Map();

  static async switchApi(url = 'http://localhost:5001/') {
    ApiClient.API_URL = url;
    ApiClient.init();
  }

  static async init() {
    this.client = axios.create({
      baseURL: this.API_URL + 'api/',
    });

    // Auth interceptor
    this.client.interceptors.request.use(
      (config) => {
        const accessToken = localStorageService.getAccessToken();
        if (accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    // Refresh token interceptor
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        if (!error.response || !error.response.status) {
          history.push(coreRoutes.error);
        }
        console.log(originalRequest);
        if (
          error?.response?.status === 404 &&
          !originalRequest.url.includes('images')
        ) {
          history.push(coreRoutes.notFound);
        }
        if (error?.response?.status === 401 && !originalRequest._retry) {
          if (this.isRefreshing) {
            return this._pushToRefreshWaitingQueue(originalRequest);
          }

          this.isRefreshing = true;

          originalRequest._retry = true;
          return this.client
            .post('account/refresh-access-token', {
              refreshToken: localStorageService.getRefreshToken(),
              accessToken: localStorageService.getAccessToken(),
            })
            .then(
              (res) => {
                console.log(res);
                if (res.status === 200) {
                  const tokenData = res.data.value.tokenData;
                  console.log('tokenRefreshed: ', tokenData);
                  // put token to LocalStorage & store
                  store.dispatch(login(tokenData));
                  // change Authorization header
                  this.client.defaults.headers.common['Authorization'] =
                    'Bearer ' + tokenData.accessToken;
                  this.isRefreshing = false;
                  // handling other request
                  this._resolveRefreshWaitingQueue(tokenData.accessToken);

                  // return originalRequest object with Axios.
                  return this.client(originalRequest);
                } else
                  throw new Error(
                    'cannot refresh token with status ' + res.status
                  );
              },
              (error) => {
                console.log(
                  `Error refreshing token, redirecting to main page: `,
                  error.response
                );
                this.isRefreshing = false;
                this._rejectRefreshWaitingQueue(error);
                store.dispatch(logout());
                history.push(loginRoutes.root);
                throw new Error('Error refreshing token');
              }
            );
        }
        if (error?.response?.status === 403) {
          this.getOwnSecurityGroups()
            .then((newGroups) => {
              store.dispatch(updateUserSecurityGroups(newGroups));
            })
            .catch((err) =>
              console.log(
                'error updating own security groups',
                err,
                err.response
              )
            );
        } else {
          // console.log(
          //   `Error refreshing token, redirecting to main page: `,
          //   error.response
          // );
          // store.dispatch(logout());
          // history.push(loginRoutes.root);
          // throw new Error('Error refreshing token');
          return Promise.reject(error);
        }
      }
    );
  }

  static hubConnection;

  static async startConnection() {
    console.log(this.API_URL, 'api url');
    console.log('members connection [][][]');
    this.hubConnection = new signalR.HubConnectionBuilder()
      // .configureLogging(signalR.LogLevel.Trace)
      .withUrl(this.API_URL + 'notifications', {
        // withCredentials: false,
        // transportType: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorageService.getAccessToken(),
      })
      .withAutomaticReconnect()
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 900000;
    this.hubConnection.keepAliveIntervalInMilliseconds = 450000;
    await this.hubConnection
      .start()
      .then(() => {
        console.log('[Notifications Signal R] Connection started');
      })
      .catch((err) => {
        console.warn(err, '[Notifications Signal R hub connection error]');
      });
    // this.hubConnection.onclose((err) => {
    //   console.log('[Notifications Signal R] onclose fired');
    //   if (err) {
    //     console.log(err, '[Notifications Signal R] onclose err');
    //   }
    // });
  }

  static async getRecentNotification(setRecentNotification) {
    this.hubConnection.on('AdminNotificationMessage', (message) => {
      console.log(message, 'from separated signalR');
      setRecentNotification(message);
    });
  }

  static async getBellIndicator(setBellIndicator) {
    this.hubConnection.on('AllNotificationsAreReadMessage', (indicator) => {
      setBellIndicator(indicator);
      console.log(indicator, 'indicator');
    });
  }

  static _resolveRefreshWaitingQueue(token) {
    for (let promise of this.refreshWaitingQueue) {
      promise.resolve(token);
    }
    this.refreshWaitingQueue = [];
  }

  static _rejectRefreshWaitingQueue(error) {
    for (let promise of this.refreshWaitingQueue) {
      promise.reject(error);
    }
    this.refreshWaitingQueue = [];
  }

  static _pushToRefreshWaitingQueue(originalRequest) {
    return new Promise((resolve, reject) => {
      this.refreshWaitingQueue.push({ resolve, reject });
    })
      .then((token) => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return axios(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  /**
   *  Gets image by it's hash. If image is already being requested new request will not
   *  be created.
   * @param {string} controllerPath API controller name where /images/{hash} is located
   * @param {string} hash
   * @returns Promise<Blob>
   */
  static async getImage(controllerPath, hash) {
    const queuedRequest = this.imageRequestQueueMap.get(hash);

    let response;

    if (queuedRequest) {
      queuedRequest.subscribedRequestsCount++;
      response = await queuedRequest.originalRequest;
      queuedRequest.subscribedRequestsCount--;

      if (queuedRequest.subscribedRequestsCount === 0) {
        this.imageRequestQueueMap.set(hash, undefined);
      }
    } else {
      const requestPromise = this.client.get(
        `${controllerPath}/images/${hash}`,
        {
          responseType: 'blob',
          timeout: 30000,
        }
      );

      const newQueuedRequest = {
        originalRequest: requestPromise,
        subscribedRequestsCount: 0,
      };

      this.imageRequestQueueMap.set(hash, newQueuedRequest);

      response = await requestPromise;

      if (this.imageRequestQueueMap.get(hash).subscribedRequestsCount === 0) {
        this.imageRequestQueueMap.set(hash, undefined);
      }
    }

    return response.data;
  }

  static async _getBinaryFileByUrl(url, params = {}) {
    const response = await this.client.get(url, {
      responseType: 'blob',
      timeout: 60000,
      headers: {
        accept: '*/*',
      },
      params,
    });

    const file = response.data;
    file.name = response.headers['content-disposition']
      ?.split('filename=')?.[1]
      ?.replaceAll('"', '')
      ?.replaceAll('_', ' ');

    return file;
  }

  static async createDontaion(donationData) {
    return await this.client.post('donations', donationData);
  }

  static getDonationProgress() {
    return fetch(`${this.API_URL}api/donation-goals/current-progress`, {
      method: 'GET',
    });
  }

  static async login(email, password) {
    const response = await this.client.post('account/login', {
      email,
      password,
    });
    return response.data.value;
  }

  static async logout(accessToken, refreshToken) {
    // for some reason axios do not work here

    await fetch(`${this.API_URL}api/account/logout`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8', // Indicates the content
      },
      body: JSON.stringify({
        accessToken,
        refreshToken,
      }),
    });

    // const response = await this.client.delete('account/logout', {
    //   data: {
    //     accessToken,
    //     refreshToken,
    //   },
    // });
    // return response.data.value;
  }

  static async register({ email, name, password, repeatPassword }) {
    const response = await this.client.post('account', {
      email,
      name,
      password,
      repeatPassword,
    });
    console.log(response);
    return response.data;
  }

  static async confirmEmail({ id, token }) {
    const response = await this.client.post('account/confirm-email', {
      id,
      token,
    });
    return response.data;
  }

  static async forgotPasswordEmail(email) {
    const response = await this.client.post('account/forgot-password-email', {
      email,
    });
    return response.data;
  }

  static async resendConfirmationEmail(email) {
    const response = await this.client.post(
      'account/resend-confirmation-email',
      {
        email,
      }
    );
    return response.data.value;
  }

  static async resetPassword({ id, token, password, confirmPassword }) {
    const response = await this.client.post('account/reset-password', {
      id,
      token,
      password,
      confirmPassword,
    });
    return response.data;
  }

  static async getUserInfo() {
    const response = await this.client.get('account/current');
    return response.data.value;
  }

  static async updateUserInfo(updates) {
    console.log('request data', updates);
    const toSend = { ...updates };

    if (updates?.image) {
      toSend.image = await this._handleImage(updates.image, true);
    } else {
      toSend.image = { isChanged: true };
    }
    const response = await this.client.put('account/update', toSend);
    console.log(response);
    return response.data;
  }

  static async uploadImage(data) {
    const response = await this.client.post('files/upload-image', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'text/plain',
      },
    });
    console.log(response);
    return response;
  }

  // static async _handleImage(image) {
  //   if (!image) return;

  //   if (!image.file && image.url) {
  //     return image.url;
  //   }

  //   if (typeof image === 'string' && image !== '') {
  //     return image;
  //   }

  //   const formData = new FormData();

  //   const compressedFile = await ImageCompressionService.compress(image.file);

  //   formData.append('file', compressedFile);
  //   const response = await ApiClient.uploadImage(formData);
  //   return response.data.value;
  // }

  static async _handleImage(image, update = false) {
    if (!image) return;

    if (typeof image === 'string' && image !== '') {
      return { imageData: '', isChanged: update ? false : undefined };
    }

    const compressedFile = await ImageCompressionService.compress(image.file);

    return {
      imageData: await FileUtils.blobToBase64(compressedFile),
      isChanged: update ? true : undefined,
    };
  }

  static async _handlePostItem(postItem, i, update = false) {
    return {
      id: postItem.id,
      heading: postItem.heading,
      text: postItem.text,
      videoUrl: postItem.videoUrl,
      image: await this._handleImage(postItem.image, update),
      orderNumber: i + 1,
    };
  }

  static async createPost(post) {
    const toSend = {
      text: post.postText,
      heading: post.heading,
      image: await this._handleImage(post.image),
      postItems: await Promise.all(
        post.postItems.map((postItem, i) => this._handlePostItem(postItem, i))
      ),
    };
    console.log('to send', toSend);
    const response = await this.client.post('posts/', toSend);
    console.log(response);
    return response;
  }

  static async fetchPosts(currentPage, pageSize) {
    const response = await this.client.get(
      `posts?CurrentPage=${currentPage}&PageSize=${pageSize}`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    console.log(response);
    return {
      ...response.data.value,
      entities: response.data.value.entities.map((post) => {
        return {
          postText: post.text,
          heading: post.heading,
          // headingImage: {
          //   url: post.titlePictureUrl,
          //   file: null,
          // },
          image: post.image,
          createdAt: post.createdAt,
          id: post.id,
        };
      }),
    };
  }
  static async fetchDeletedPosts(currentPage, pageSize) {
    const response = await this.client.get(
      `posts/deleted?CurrentPage=${currentPage}&PageSize=${pageSize}`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    console.log(response);
    return {
      ...response.data.value,
      entities: response.data.value.entities.map((post) => {
        return {
          postText: post.text,
          heading: post.heading,
          image: post.image,

          // headingImage: {
          //   url: post.titlePictureUrl,
          //   file: null,
          // },
          createdAt: post.createdAt,
          id: post.id,
        };
      }),
    };
  }

  static async fetchFullPost(postId) {
    const response = await this.client.get(`posts/${postId}`);
    console.log(response);
    return response;
  }

  static async updatePost(post) {
    const { id, postText, heading, image, postItems, createdAt } = post;
    const response = await this.client.put(`posts`, {
      id,
      text: postText,
      heading,
      createdAt,
      image: await this._handleImage(image, true),
      postItems: await Promise.all(
        postItems.map((postItem, i) => this._handlePostItem(postItem, i, true))
      ),
    });
    console.log(response);
    return response;
  }

  static async deletePostTemporary(postId) {
    const response = await this.client.delete(`posts/${postId}`);
    return response;
  }

  static async restorePost(postId) {
    const response = await this.client.put(`posts/${postId}/restore`);
    return response;
  }

  static async deletePostPermanently(postId) {
    const response = await this.client.delete(`posts/${postId}/permanently`);
    return response;
  }

  static async fetchDonations(currentPage, pageSize) {
    const response = await this.client.get(
      `donations?CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response;
  }

  static async fetchPostsWithItems(currentPage, pageSize) {
    const response = await this.client.get(
      `posts/with-items?CurrentPage=${currentPage}&PageSize=${pageSize}`,
      {
        headers: {
          accept: 'application/json',
        },
      }
    );
    console.log(response);
    return {
      ...response.data.value,
      entities: response.data.value.entities.map((post) => {
        return {
          postText: post.text,
          heading: post.heading,
          // image: {
          //   url: post.image,
          //   file: null,
          // },
          createdAt: post.createdAt,
          id: post.id,
          postItems: post.postItems,
        };
      }),
    };
  }

  static async addPaymentMethod({ number, expMonth, expYear, cvc }) {
    const response = await this.client.post(`payment-methods/`, {
      number,
      expMonth,
      expYear,
      cvc,
    });
    console.log(response);
    return response;
  }

  static async deletePaymentMethod(methodId) {
    const response = await this.client.delete(`payment-methods/${methodId}`);
    console.log(response);
    return response;
  }

  static async getPaymentMethods() {
    const response = await this.client.get(`payment-methods`);
    console.log(response);
    return response.data.value.paymentMethods;
  }

  static async setDefaultPaymentMethod(methodId) {
    const response = await this.client.put(
      `payment-methods/default/${methodId}`
    );
    console.log(response);
    return response;
  }

  // Address

  static async getAddress() {
    const response = await this.client.get(`account/current/address`);
    console.log(response);
    return response.data.value;
  }

  /**
   * Updates user's address
   * @param {fullName, addressLine, city, state, country, zipCode, email} shipmentAddress
   * @param {fullName, addressLine, city, state, country, zipCode, email} billingAddress
   */
  static async updateAddress(
    shippingAddress,
    billingAddress,
    billingAddressIsEqual
  ) {
    const response = await this.client.put(`account/update/address`, {
      shippingAddress,
      billingAddress,
      billingAddressIsEqual,
    });
    console.log(response);
    return response;
  }

  static async fetchProducts(currentPage, pageSize, categoryId) {
    const response = await this.client.get(
      `products?CategoryId=${categoryId}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response.data.value;
  }

  static async createProduct(data) {
    if (data.pictures.length > 0) {
      data.pictures[0].isDefault = true;
      for (let i = 0; i < data.pictures.length; i++) {
        const picture = data.pictures[i];
        data.pictures[i] = {
          imageData: picture.url
            ? (await this._handleImage(picture)).imageData
            : undefined,
          isDefault: !!picture.isDefault,
        };
      }
    }

    data.productCreator = {
      name: data.productCreator.name,
      image: await this._handleImage(data.productCreator.image),
    };

    const response = await this.client.post('products', {
      ...data,
    });

    return response;
  }

  static async fetchFullProduct(id) {
    const response = await this.client.get(`products/${id}`);
    console.log(response);
    return response.data.value;
  }

  static async updateProduct(data) {
    console.log(data);
    if (data.pictures.length > 0) {
      data.pictures[0].isDefault = true;
      for (let i = 0; i < data.pictures.length; i++) {
        const picture = data.pictures[i];
        data.pictures[i] = {
          imageData: picture.url
            ? (await this._handleImage(picture)).imageData
            : undefined,
          hash: picture.image,
          isDefault: !!picture.isDefault,
        };
      }
    }

    data.productCreator = {
      name: data.productCreator.name,
      image: await this._handleImage(data.productCreator.image, true),
    };

    const response = await this.client.put('products', {
      ...data,
    });

    return response;
  }

  static async deleteProduct(id) {
    const response = await this.client.delete(`products/${id}`);

    return response;
  }

  static async updateProductAvailability(id, inStock) {
    const response = await this.client.put('products/availability', {
      id,
      inStock,
    });
    return response;
  }

  static async fetchOrders(currentPage, pageSize, statusId) {
    const response = await this.client.get(
      `orders?StatusId=${statusId}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response;
  }

  static async createOrder(orderData) {
    console.log('creating order', orderData);
    const response = await this.client.post(`orders`, orderData);
    console.log(response);
    return response.data.value;
  }

  static async fetchOwnOrders(currentPage, pageSize) {
    const response = await this.client.get(
      `orders/current?&CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response;
  }

  static async createJobPosition(jobPositionData) {
    const response = await this.client.post(`job-positions`, jobPositionData);
    console.log(response);
    return response.data.value;
  }

  static async getJobPositions() {
    const response = await this.client.get(`job-positions/all`);
    console.log(response);
    return response.data.value;
  }

  static async getJobPosition(id) {
    const response = await this.client.get(`job-positions/${id}`);
    console.log(response);
    return response.data.value;
  }

  static async createJobApplication({ jobPositionId, name, email, resume }) {
    const formData = new FormData();
    formData.append('Resume', resume);
    formData.append('JobPositionId', jobPositionId);
    formData.append('Name', name);
    formData.append('Email', email);
    const response = await this.client.post(
      `job-applications`,

      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'text/plain',
        },
      }
    );
    console.log(response);
    return response.data.value;
  }

  static async fetchJobApplications(currentPage, pageSize, desc, sortBy) {
    const response = await this.client.get(
      `job-applications?SortBy=${sortBy}&Desc=${desc}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response;
  }

  static async fetchJobPositions(currentPage, pageSize) {
    const response = await this.client.get(
      `job-positions?CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response;
  }

  static async deletePosition(posId) {
    const response = await this.client.delete(`job-positions/${posId}`);
    return response;
  }

  static async editJobPosition(jobPositionData) {
    const response = await this.client.put(`job-positions`, jobPositionData);
    console.log(response);
    return response.data.value;
  }

  static async deleteApplication(applicationId) {
    const response = await this.client.delete(
      `job-applications/${applicationId}`
    );
    return response;
  }
  static async downloadApplicationResume(hash) {
    const response = await this.client.get(`job-applications/resumes/${hash}`, {
      responseType: 'blob',
      timeout: 60000,
    });

    console.log('Headers', response.headers);

    return {
      blob: response.data,
      fileName:
        response.headers['content-disposition']?.split('filename=')?.[1] ??
        '.pdf',
    };
  }

  static async fetchTrainings(currentPage, pageSize) {
    const response = await this.client.get(
      `trainings?CurrentPage=${currentPage}&PageSize=${pageSize}`
    );
    console.log(response);
    return response.data.value;
  }

  static async updateTraining(training) {
    const payload = {
      ...training,
      image: await this._handleImage(training.image, true),
      price: (Number(training.price) * 100) ^ 0,
      timeInMin: Number(training.timeInMin),
      lessons: await Promise.all(
        training.lessons.map(async (lesson) => ({
          ...lesson,
          timeInMin: Number(lesson.timeInMin),
          test: lesson.test
            ? {
                ...lesson.test,
                isFinal: !!lesson.test.isFinal,
              }
            : null,
          lessonTopics: await Promise.all(
            lesson.lessonTopics.map(async (topic, i) => ({
              ...topic,
              orderNumber: i + 1,
              timeInMin: Number(topic.timeInMin),
              lessonContentParts: await Promise.all(
                topic.lessonContentParts.map(async ({ image, ...rest }, j) => ({
                  ...rest,
                  orderNumber: j + 1,
                  image: await this._handleImage(image),
                }))
              ),
            }))
          ),
        }))
      ),
    };
    console.log(payload);
    const response = await this.client.put(`trainings`, payload);
    console.log(response);
    return response;
  }

  static async createTraining(training) {
    const payload = {
      ...training,
      image: await this._handleImage(training.image),
      price: (Number(training.price) * 100) ^ 0,
      timeInMin: Number(training.timeInMin),
      lessons: await Promise.all(
        training.lessons.map(async (lesson) => ({
          ...lesson,
          timeInMin: Number(lesson.timeInMin),
          test: lesson.test
            ? {
                ...lesson.test,
                isFinal: !!lesson.test.isFinal,
              }
            : null,
          lessonTopics: await Promise.all(
            lesson.lessonTopics.map(async (topic, i) => ({
              ...topic,
              orderNumber: i + 1,
              timeInMin: Number(topic.timeInMin),
              lessonContentParts: await Promise.all(
                topic.lessonContentParts.map(async ({ image, ...rest }, j) => ({
                  ...rest,
                  orderNumber: j + 1,
                  image: await this._handleImage(image),
                }))
              ),
            }))
          ),
        }))
      ),
    };
    console.log(payload);
    const response = await this.client.post(`trainings`, payload);
    console.log(response);
    return response;
  }

  static async deleteTraining(trainingId) {
    const response = await this.client.delete(`trainings/${trainingId}`);
    return response;
  }

  static async fetchFullTraining(trainingId) {
    const response = await this.client.get(`trainings/${trainingId}`);
    console.log(response);
    return response.data.value;
  }

  static async createTrainingOrder(trainingOrderData) {
    console.log(trainingOrderData);
    const response = await this.client.post(
      `trainings/orders`,
      trainingOrderData
    );
    console.log(response);
    return response.data.value;
  }

  static async getCertificates() {
    const response = await this.client.get(`trainings/current/certificates`);
    console.log(response);
    return response.data.value;
  }

  static async getUserTraining(trainingId) {
    const response = await this.client.get(`trainings/current/${trainingId}`);
    console.log(response);
    return response.data.value;
  }

  static async getLesson(lessonId) {
    const response = await this.client.get(
      `trainings/current/lessons/${lessonId}`
    );
    console.log(response);
    return response.data.value;
  }

  static async getTopic(topicId) {
    const response = await this.client.get(
      `trainings/current/topics/${topicId}`
    );
    console.log(response);
    return response.data.value;
  }

  static async completeTopic(topicId) {
    const response = await this.client.put(
      `trainings/current/topics/${topicId}`
    );
    console.log(response);
    return response.data.value;
  }

  static async verifyTest(testData) {
    // debugger;
    console.log('verifyTest: ', testData);
    const response = await this.client.put(`trainings/verify-test`, testData);
    console.log(response);
    return response.data.value;
  }

  static async fetchTrainingOrders(currentPage, pageSize, statusId) {
    const response = await this.client.get(
      `trainings/orders?CurrentPage=${currentPage}&PageSize=${pageSize}&StatusId=${statusId}`
    );
    console.log(response);
    return response;
  }

  static async fetchUserTrainings(
    currentPage,
    pageSize,
    isCompleted,
    searchString
  ) {
    const response = await this.client.get(`trainings/students`, {
      params: {
        currentPage,
        pageSize,
        isCompleted,
        searchString,
      },
    });
    console.log(response);
    return response;
  }

  static async getSecurityGroups() {
    const response = await this.client.get(`security-groups`);
    console.log(response);
    return response.data.value;
  }

  static async getOwnSecurityGroups() {
    const response = await this.client.get(`account/security-groups`);
    console.log(response);
    return response.data.value.securityGroups;
  }

  static async getAllPermissions() {
    const response = await this.client.get(`permissions`);
    console.log(response);
    return response.data.value;
  }
  static async updateStaffUser(data) {
    const response = await this.client.put('users/staff', data);
    return response;
  }

  static async getUsersByEmail(email, facilityId) {
    if (!email || email.length < 3) return [];
    const response = await this.client.get(`users`, {
      params: { email, facilityId },
    });
    return response.data.value.users;
  }

  static async inviteUserByEmail(email, securityGroupsIds, updatedFacilityId) {
    const response = await this.client.post(`users/staff`, {
      email,
      securityGroupsIds,
      updatedFacilityId,
    });
    return response;
  }

  static async fetchUsers(CurrentPage, PageSize, IsActiveStaff) {
    const response = await this.client.get(`users/staff`, {
      params: { CurrentPage, PageSize, IsActiveStaff },
    });
    console.log(response);
    return response;
  }

  static async contactSales(payload) {
    const response = await this.client.post(`contact/sales`, payload);
    return response;
  }

  static async setStaffActive(userId, isActiveStaff) {
    const response = await this.client.put(`users/staff/activation `, {
      userId,
      isActiveStaff,
    });
    return response;
  }

  static async fetchResidents(CurrentPage, PageSize, Name, DatePlaced, Status) {
    const response = await this.client.get(`residents`, {
      params: { CurrentPage, PageSize, Name, DatePlaced, Status },
    });
    console.log(response);
    return response;
  }

  static async fetchResidentsStaffBySearch(
    CurrentPage,
    PageSize,
    SearchString,
    FacilityId
  ) {
    const response = await this.client.get(`residents/staff-on-duty`, {
      params: { CurrentPage, PageSize, SearchString, FacilityId },
    });
    return response;
  }

  static async fetchResidentsDevices(SearchString) {
    const response = await this.client.get(`resident-devices`, {
      params: { SearchString },
    });
    return response.data.value.devices;
  }

  static async createResidentDevice(name) {
    const response = await this.client.post(`resident-devices`, {
      name,
    });
    return response.data.value;
  }

  static async getResident(residentId) {
    const response = await this.client.get(`residents/${residentId}`);
    console.log(response);
    return response.data.value;
  }

  static async createResident(formData) {
    const response = await this.client.post(`residents`, formData);
    console.log(response);
    return response.data.value;
  }
  static async editResident(formData) {
    const response = await this.client.put(`residents`, formData);
    console.log(response);
    return response.data.value;
  }

  static async getResidentReferral(id) {
    const response = await this.client.get(`residents/referral/${id}`, {
      responseType: 'blob',
      timeout: 30000,
    });

    return response.data;
  }

  static async getResidentDailyLog(logId) {
    const response = await this.client.get(`resident-logs/daily/${logId}`);
    console.log(response);
    return response.data.value;
  }

  static async createResidentDailyLog(log) {
    const response = await this.client.post(`resident-logs/daily`, log);
    return response.data.value;
  }

  static async updateResidentDailyLog(log) {
    const response = await this.client.post(
      `resident-logs/daily/new-version`,
      log
    );
    return response.data.value;
  }

  static async createResidentGroupDailyLog(log) {
    const response = await this.client.post(`resident-logs/group-daily`, log);
    return response.data.value;
  }

  static async getResidentDailyLogs(residentId, date) {
    const response = await this.client.get(`resident-logs/daily`, {
      params: { residentId, date },
    });
    console.log(response);
    return response.data.value;
  }

  static async getResidentDailyLogPrint(ResidentId, Date, TimePeriod) {
    return this._getBinaryFileByUrl('resident-logs/daily/document', {
      ResidentId,
      Date,
      TimePeriod,
    });
  }

  static async getResidentWeeklyLogs(residentId, fromDate, toDate) {
    const response = await this.client.get(`resident-logs/weekly`, {
      params: { residentId, fromDate, toDate },
    });
    console.log(response);
    return response.data.value;
  }

  static async editResidentWeeklyLog(
    residentId,
    fromDate,
    toDate,
    description
  ) {
    return await this.client.put(`resident-logs/weekly`, {
      residentId,
      fromDate,
      toDate,
      description,
    });
  }

  static async getResidentWeeklyLogPrint(ResidentId, FromDate, ToDate) {
    return this._getBinaryFileByUrl('resident-logs/weekly/document', {
      ResidentId,
      FromDate,
      ToDate,
    });
  }

  static async getResidentMonthlyLogs(residentId, year) {
    const response = await this.client.get(`resident-logs/monthly`, {
      params: { residentId, year },
    });
    console.log(response);
    return response.data.value;
  }

  static async createResidentMonthlyLog(residentId, displayDate) {
    const response = await this.client.post(`resident-logs/monthly`, {
      residentId,
      displayDate,
    });
    return response.data.value;
  }

  static async getMonthlyLogFile(id) {
    return await this._getBinaryFileByUrl(
      `resident-logs/monthly/${id}/document`
    );
  }

  /**
   * Creates GET method wrapper, that can be used by MultipageForm
   * for getting sections
   * @param {string} baseApiPath start of the path that should not include entryId
   * @param {number | string} entryId main entry id (log, facility, etc)
   * @param {string} section name of the section that will be used at the and of the path
   */
  static createGetMultipageFormSection(baseApiPath, entryId, section) {
    return async () => {
      if (baseApiPath === 'NOT_IMPLEMENTED') {
        return {
          commonInfo: {
            isFirstVersion: true,
            isDraft: true,
            isEditable: true,
            versions: [],
            sectionInfo: {
              isSectionNew: true,
            },
          },
        };
      }
      const response = await this.client.get(
        `${baseApiPath}/${entryId}${section}`
      );
      // console.log(`${baseApiPath}: `, response.data);

      return response.data.value;
    };
  }
  /**
   * Update section method used by MultipageForm
   * @param {string} baseApiPath start of the path that should not include entryId
   * @param {number | string} entryId main entry id (log, facility, etc)
   * @param {string} section name of the section that will be used at the and of the path
   * @param {any} updates
   */
  static async updateMultipageFormSection(
    baseApiPath,
    entryId,
    section,
    updates,
    isFormData
  ) {
    if (baseApiPath === 'NOT_IMPLEMENTED') {
      return {
        commonInfo: {
          isFirstVersion: true,
          isDraft: true,
          isEditable: true,
          versions: [],
        },
      };
    }

    let response;
    if (isFormData) {
      response = await this.client.put(
        `${baseApiPath}${section}`,

        serializeToFormData({
          id: entryId,
          ...updates,
        }),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: 'text/plain',
          },
        }
      );
    } else {
      response = await this.client.put(`${baseApiPath}${section}`, {
        id: entryId,
        ...updates,
      });
    }

    return response;
  }

  /**
   * Initiates editing in MultipageForm. After it entry moves to Draft state.
   * @param {string} baseApiPath start of the path that should not include entryId
   * @param {number | string} entryId main entry id (log, facility, etc)
   */
  static async startEditingMultipageForm(baseApiPath, entryId) {
    if (baseApiPath === 'NOT_IMPLEMENTED') {
      return entryId + 1;
    }

    const response = await this.client.post(`${baseApiPath}/new-version`, {
      id: entryId,
    });
    return response.data.value;
  }
  /**
   * Confirms draft entry changes in MultipageForm.
   * @param {string} baseApiPath start of the path that should not include entryId
   * @param {number | string} entryId main entry id (log, facility, etc)
   */
  static async confirmMultipageFormChanges(
    baseApiPath,
    entryId,
    reasonForUpdate
  ) {
    if (baseApiPath === 'NOT_IMPLEMENTED') {
      return entryId + 1;
    }

    const response = await this.client.put(`${baseApiPath}/changes`, {
      id: entryId,
      reasonForUpdate,
    });
    return response;
  }

  static async discardMultipageFormChanges(baseApiPath, entryId) {
    if (baseApiPath === 'NOT_IMPLEMENTED') {
      return entryId + 1;
    }

    const response = await this.client.delete(
      `${baseApiPath}/${entryId}/changes`
    );
    return response;
  }

  /**
   * @param {string} baseApiPath start of the path that should not include entryId
   * @param {number | string} entryId main entry id (log, facility, etc)
   */
  static async getMultipageFormDocumentPrint(baseApiPath, entryId) {
    if (baseApiPath === 'NOT_IMPLEMENTED') {
      return entryId + 1;
    }

    return this._getBinaryFileByUrl(`${baseApiPath}/${entryId}/document`);
  }

  static async fetchFacilities(
    CurrentPage,
    PageSize,
    SearchString,
    isActivated
  ) {
    if (SearchString && SearchString.length < 2)
      return { data: { value: { entries: [], totalItems: 0 } } };

    // return { data: { value: { entries: [], totalItems: 0 } } };
    const response = await this.client.get(`facilities`, {
      params: { CurrentPage, PageSize, SearchString, isActivated },
    });
    console.log(response);
    return response;
  }

  static async fetchShortFacilities(CurrentPage, PageSize, SearchString) {
    if (SearchString && SearchString.length < 2)
      return { data: { value: { entries: [], totalItems: 0 } } };

    const response = await this.client.get(`facilities/short-info`, {
      params: { CurrentPage, PageSize, SearchString },
    });
    console.log(response);
    return response;
  }

  static async getFacility(id) {
    const response = await this.client.get(`facilities/${id}`);
    console.log(response);
    return response.data.value;
  }
  static async getOwnFacility() {
    const response = await this.client.get(`facilities/my`);
    console.log(response);
    return response.data.value;
  }

  static async setOwnFacility(id) {
    const response = await this.client.put(`facilities/my`, { id });
    return response;
  }

  static async setAllFacilitiesMode() {
    const response = await this.client.put(`facilities/my/all`);
    return response;
  }

  static async editFacility(data) {
    const response = await this.client.put(`facilities`, data);
    console.log(response);
    return response.data.value;
  }

  static async createFacility(data) {
    const response = await this.client.post(`facilities`, data);
    console.log(response);
    return response.data.value;
  }

  static async setFacilityActivationStatus(id, value) {
    const response = await this.client.put(`facilities/activation`, {
      id,
      isActivated: value,
    });
    console.log(response);
    return response.data.value;
  }

  static async getAffectedResidentsAfterFacilityChange(staffId, facilityId) {
    const response = await this.client.get(
      `users/staff/fetched-residents/${staffId}`,
      {
        params: { facilityId },
      }
    );

    return response.data.value.fetchedResidentsCount;
  }

  static async createResidentScheduleEvent(data) {
    const response = await this.client.post(`schedule-events`, data);

    return response.data.value;
  }

  static async updateResidentScheduleEvent(data) {
    const response = await this.client.put(`schedule-events`, data);

    return response.data.value;
  }

  static async getResidentScheduleEvents(residentId, startDate, endDate) {
    const response = await this.client.get(`schedule-events`, {
      params: {
        residentId,
        startDate,
        endDate,
      },
    });

    return response.data.value.events.map(
      ({ startDateTime, endDateTime, ...rest }) => ({
        start: new Date(startDateTime),
        end: new Date(endDateTime),
        ...rest,
      })
    );
  }

  static async getResidentFullScheduleEvent(eventId) {
    const response = await this.client.get(
      `schedule-events/${encodeURIComponent(eventId)}`
    );
    return response.data.value;
  }

  static async editResidentScheduleEventTime(
    id,
    startDateTime,
    endDateTime,
    affectionModeId
  ) {
    const response = await this.client.put(`schedule-events/quick`, {
      id,
      startDateTime,
      endDateTime,
      affectionModeId,
    });

    return response.data.value;
  }

  static async deleteResidentScheduleEvent(id, affectionModeId) {
    await this.client.delete(`schedule-events/${encodeURIComponent(id)}`, {
      params: { affectionModeId },
    });
  }

  static async subscribeOnResidentScheduleEvent(id, subscribeMode) {
    await this.client.post(`schedule-events/subscription/event/on`, {
      id,
      subscribeMode,
    });
  }

  static async unsubscribeOnResidentScheduleEvent(eventId) {
    await this.client.delete(
      `schedule-events/subscription/event/off/${encodeURIComponent(eventId)}`
    );
  }

  static async updateSubscriptionOnResidentSchedule(residentId, isSubscribed) {
    await this.client.put(`schedule-events/subscription/resident`, {
      residentId,
      isSubscribed,
    });
  }

  static async getResidentMedicationLog(id) {
    const response = await this.client.get(`medications/${id}`);
    return response.data.value;
  }

  static async fetchMedicationLogs(CurrentPage, PageSize, residentId, date) {
    const response = await this.client.get(`medications`, {
      params: { CurrentPage, PageSize, date, residentId },
    });

    return response;
  }

  static async createMedicationLog(log) {
    const response = await this.client.post('medications', log);
    return response.data.value;
  }

  static async updateMedicationLog(log) {
    await this.client.put('medications', log);
  }

  static async getMedicationLogEvents(logId, date) {
    const response = await this.client.get('medications/events', {
      params: {
        medicationLogId: logId,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
      },
    });
    console.log(response);
    return response.data.value.events;
  }

  static async updateMedicationLogEvents(logId, residentId, events) {
    await this.client.put('medications/events', {
      medicationLogId: logId,
      residentId,
      events,
    });
  }

  static async getMedicationLogDocument(residentId) {
    return this._getBinaryFileByUrl(`medications/${residentId}/document`);
  }

  static async fetchResidentReports(currentPage, pageSize, residentId) {
    const response = await this.client.get(`resident-reports`, {
      params: { currentPage, pageSize, residentId },
    });
    return response;
  }

  static async createReport(type, residentId) {
    try {
      const response = await this.client.post(`resident-reports/${type}`, {
        residentId,
      });
      return response.data.value;
    } catch (err) {
      return;
    }
  }

  static async fetchShortResidents(currentPage, pageSize, name, facilityId) {
    const response = await this.client.get(`residents/short`, {
      params: { currentPage, pageSize, name, facilityId },
    });
    return response;
  }

  static async getFacilityReports(facilityId, year) {
    const response = await this.client.get(`facility-reports`, {
      params: { facilityId, year },
    });
    return response.data.value;
  }

  static async getFacilityMonthlyCostReports(facilityId, year) {
    return {
      groupedReports: [
        { reports: [], displayDate: new Date() },
        { reports: [], displayDate: new Date() },
        { reports: [], displayDate: new Date() },
        { reports: [], displayDate: new Date() },

        {
          displayDate: new Date(),
          reports: [
            {
              id: 1,
              loggedDate: '29/01/2020',
              sectionPath: 'first-step',
              isDraft: true,
              isExpired: false,
              isEditable: true,
              editorName: 'Test',
              lastEdited: new Date(),
              stepsProgress: {
                totalStepsCount: 5,
                filledStepsCount: 3,
              },
            },
          ],
        },
        { reports: [], displayDate: new Date() },
      ],
      totalLogs: 0,
    };
  }

  static async getFacilityYearlyCostReports(facilityId, year) {
    return {
      groupedReports: [
        { reports: [], displayDate: new Date() },
        { reports: [], displayDate: new Date() },

        {
          displayDate: new Date(),
          reports: [
            {
              id: 1,
              loggedDate: '29/01/2020',
              sectionPath: 'first-step',
              isDraft: true,
              isExpired: false,
              isEditable: true,
              editorName: 'Test',
              lastEdited: new Date(),
              stepsProgress: {
                totalStepsCount: 5,
                filledStepsCount: 3,
              },
            },
          ],
        },
        { reports: [], displayDate: new Date() },
      ],
      totalLogs: 0,
    };
  }

  static async fetchHiredStaffBySearch(
    CurrentPage,
    PageSize,
    SearchString,
    FacilityId
  ) {
    const response = await this.client.get(`users/staff/facility`, {
      params: { CurrentPage, PageSize, SearchString, FacilityId },
    });
    return response;
  }

  static async createFacilityMonthlyReport(facilityId, displayDate) {
    const response = await this.client.post(`facility-reports`, {
      facilityId,
      displayDate,
    });
    return response.data.value;
  }

  static async getFile(endpoint, hash) {
    return this._getBinaryFileByUrl(`${endpoint}/${hash}`);
  }

  static async resolveMonthlyLogPopupUpdates(updates) {
    await this.client.put('resident-logs/monthly/pop-up', updates);
  }

  static async resolveFacilityReportPopupUpdates(updates) {
    await this.client.put('facility-reports/pop-up', updates);
  }

  static async getResidentReportFile(id, type) {
    return await this._getBinaryFileByUrl(
      `resident-reports/${type}/${id}/document`
    );
  }

  static async getFacilityReportFile(id) {
    return await this._getBinaryFileByUrl(`facility-reports/${id}/document`);
  }

  static async fetchFacilityResidentReports(
    currentPage,
    pageSize,
    facilityId,
    fromDate
  ) {
    const response = await this.client.get(`facilities/residents-reports`, {
      params: { currentPage, pageSize, facilityId, fromDate },
    });
    return response;
  }

  static async fetchRecentNotifications() {
    const response = await this.client.get(`recent-admin-notifications`);
    console.log(response);
    return response.data.value;
  }

  static async fetchNotifications(specificDate, currentPage, pageSize) {
    const response = await this.client.get(`admin-notifications`, {
      params: { specificDate, currentPage, pageSize },
    });
    console.log(response);
    return response.data.value;
  }

  static async readNotificationItem(id) {
    const response = await this.client.put(`read-admin-notification`, { id });
    return response.data.value;
  }

  static async deleteAllNotifications(specificDate) {
    await this.client.delete(`admin-notifications`, {
      data: {
        specificDate: specificDate,
      },
    });
  }

  static async readAllNotifications(specificDate) {
    const response = await this.client.put(`read-admin-notifications`, {
      specificDate,
    });
    return response.data.value;
  }

  static async fetchResidentsTypeOfContact(SearchString) {
    const response = await this.client.get(`resident-types-of-contact`, {
      params: { SearchString },
    });
    return response.data.value.typesOfContact;
  }

  static async createResidentsTypeOfContact(name) {
    const response = await this.client.post(`resident-types-of-contact`, {
      name,
    });
    return response.data.value;
  }

  static async fetchFirstNamesResidentsSimilar(facilityId, SearchString) {
    const response = await this.client.get(
      `ResidentSimilar/api/resident-similar/first-names`,
      {
        params: { facilityId, SearchString },
      }
    );
    return response.data.value;
  }

  static async fetchLastNamesResidentsSimilar(facilityId, SearchString) {
    const response = await this.client.get(
      `ResidentSimilar/api/resident-similar/last-names`,
      {
        params: { facilityId, SearchString },
      }
    );
    return response.data.value;
  }

  static async fetchCaseWorkersResidentsSimilar(facilityId, SearchString) {
    const response = await this.client.get(
      `ResidentSimilar/api/resident-similar/case-workers`,
      {
        params: { facilityId, SearchString },
      }
    );
    return response.data.value;
  }
}

if (process.env.ENABLE_API_SWITCHER) {
  window.switchApi = ApiClient.switchApi;
}

export default ApiClient;

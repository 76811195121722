import React from 'react';
import { Container as BootstrapContainer, Row, Col } from 'react-bootstrap';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';
import PersonDescription from 'modules/about/components/PersonDescription';

import './styles.scss';
// import person1Img from 'assets/images/AboutPage_person1.png';
import profileEmanuelImg from 'assets/images/AboutPage__Emanuel.png';
import profileWanikeiImg from 'assets/images/AboutPage__Wanikei.png';
import profileCerissaImg from 'assets/images/AboutPage__Cerissa.png';

const people = [
  {
    name: 'Emanuel Ashiedu',
    experience: '',
    description: `I have always worked with kids since arriving in this country in the summer of 2009. I believe the greatest investment is in people and thus my commitment in making sure every child that comes through our center will be equipped, impacted, and guided with a vision on how to believe in themselves, grow developmentally, spiritually and become self-starters.`,
    addtitionalDescription: '',
  },
  {
    name: 'Wanakei Ashiedu',
    experience: '',
    description: `Growing up, I have always seen my parents provide a haven for children who were abused, abandoned, and neglected. As an adult with children of my own, It saddens my heart when I see a child in need and as thus, I have made it a priority to help others each day. `,
    addtitionalDescription: `Starting Forstter is a way for me to give back and ensure a youth is getting the quality care they need. We will provide 
    security, stability, consistency and emotional support. As well as everyday needs like, shelter, food, clothing, medical care, and protection from harm.`,
  },
  {
    name: 'Cerissa McMillan',
    experience: '',
    description: `After tearing my ACL playing college basketball and realizing that basketball isn’t fun anymore. Backin 2016 I decided to stop chasing my first dream playing basketball and begin chasing my second dream which meant helping children out.I first started working at an boys home then continue my learning and training at saint Francis PRTF`,
    addtitionalDescription: ` 2 years later I’m the house manager at Forstter Residential Youth Center. The best part that each child brings is their different vibes and energy. Also realizing that anything is possible with a little courage.It also takes a lot of hard work, determination, and dedication. No it’s not easy but I am that special person that brings the positive energy out of each child. One thing everyone has to remember is “The storm doesn’t last forever” with that being said I will continue to make an impact on children and when they leave our facility, they will always remember Miss Cece.`,
  },
];

const MainPage = () => {
  const renderTitle = () => {
    return (
      <div className="AboutPage__titleContainer">
        <h1 className="AboutPage__title">
          About Us<span className="AboutPage__titleDot">.</span>
        </h1>
        <div className="AboutPage__redBox" />
      </div>
    );
  };

  const renderDescription = React.useMemo(() => {
    return (
      <p className="AboutPage__description">
        We are creating a youth center from the ground up to help fulfill a
        missing of helping every child living in Kansas find a home that's
        filled with love, care and every support they need to grow responsibly.
        <br /> <br />
        Our program rates different aspects of communication, youth daily living
        - creative, physical, mental and development stages. We believe every
        kid deserves equal opportunity. We believe opportunity begins with
        access.
      </p>
    );
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile }) => (
        <Container
          className="AboutPage__root"
          paddingHorizontalDesktop={'250px'}
          paddingHorizontalMobile={'18px'}
        >
          {!isMobile && (
            <BootstrapContainer>
              <Row>
                <Col sm={4}>{renderTitle()}</Col>
                <Col sm={8}>
                  <div className="AboutPage__blueBox" />
                  {renderDescription}
                </Col>
              </Row>
              <Row className="AboutPage__row">
                <Col sm={6} className="AboutPage__yelowBoxColumn">
                  <Col sm={8}>
                    <img
                      className="AboutPage__image__left"
                      src={profileEmanuelImg}
                      alt={people[0].name}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className="AboutPage__yellowBox" />
                  </Col>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <PersonDescription {...people[0]} />
                </Col>
              </Row>
              <Row className="AboutPage__secondRow">
                <Col sm={5}>
                  <PersonDescription
                    btnClassName="AboutPage__secondPersonReadMore"
                    {...people[1]}
                  />
                  <div className="AboutPage__redBackBox" />
                </Col>
                <Col sm={1} />

                <Col sm={6} className="AboutPage__yelowBoxColumn">
                  <Col sm={4} className="AboutPage__greenBlockContainer">
                    <div className="AboutPage__greenBox" />
                  </Col>
                  <Col sm={8}>
                    <img
                      className="AboutPage__image__right"
                      src={profileWanikeiImg}
                      alt={people[1].name}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className="AboutPage__row">
                <Col sm={6} className="AboutPage__yelowBoxColumn">
                  <Col sm={8}>
                    <img
                      className="AboutPage__image__left"
                      src={profileCerissaImg}
                      alt={people[2].name}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className="AboutPage__yellowBox" />
                  </Col>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <PersonDescription {...people[2]} />
                </Col>
              </Row>
            </BootstrapContainer>
          )}
          {isMobile && (
            <div className="AboutPage__containerMobile">
              {renderTitle()}
              {renderDescription}

              <div className="AboutPage__mobilePerson1Container">
                <div className="PersonDescription__title-wrapper">
                  <h1 className="PersonDescription__title">{people[0].name}</h1>
                  {/* <span className="PersonDescription__experience">
                    4 years expirience
                  </span> */}
                </div>
                <div className="AboutPage__mobileYellowBox" />
                <img
                  className="AboutPage__image__left"
                  src={profileEmanuelImg}
                  alt={people[0].name}
                />
              </div>

              <PersonDescription {...people[0]} />
              <div className="AboutPage__mobileSeparatorContainer">
                <div className="AboutPage__mobileSeparator" />
              </div>
              <div className="AboutPage__mobilePerson2Container">
                <div className="PersonDescription__title-wrapper">
                  <h1 className="PersonDescription__title">{people[1].name}</h1>
                  {/* <span className="PersonDescription__experience">
                    4 years expirience
                  </span> */}
                </div>
                <div className="AboutPage__mobileGreenBox" />

                <img
                  className="AboutPage__image__right"
                  src={profileWanikeiImg}
                  alt={people[1].name}
                />
              </div>
              <PersonDescription {...people[1]} />
              <div className="AboutPage__mobilePerson1Container">
                <div className="PersonDescription__title-wrapper">
                  <h1 className="PersonDescription__title">{people[2].name}</h1>
                  {/* <span className="PersonDescription__experience">
                    4 years expirience
                  </span> */}
                </div>
                <div className="AboutPage__mobileYellowBox" />
                <img
                  className="AboutPage__image__left"
                  src={profileCerissaImg}
                  alt={people[2].name}
                />
              </div>

              <PersonDescription {...people[2]} />
            </div>
          )}
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default MainPage;
